<!--
 * @Author: wyq
 * @Date: 2021-07-26 10:09:35
 * @LastEditTime: 2021-09-29 15:07:14
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\dialogs\msgBoxTemplate.vue
-->
<template>
  <v-overlay light :value="dialogVisible" color="#000" opacity="0.6">
    <v-card light class="msg-box-template rounded-xl">
      <img :src="imgUrl" />
      <v-btn class="btn-close" icon color="#fff" @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <div class="main">
        <p>{{title}}</p>
        <div class="content">
          <slot></slot>
          <template v-if="$slots.tip">
            <p class="tip">
              <span>*</span>
              <slot name="tip"></slot>
            </p>
            <div class="tip-placeholder"></div>
          </template>
        </div>
        <div class="btn-group">
          <slot name="btnGroup"></slot>
        </div>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import mixin from '@/utils/dialogMixin'
export default {
  mixins: [mixin],
  props: {
    imgUrl: String,
    title: String,
  },
}
</script>
<style lang="scss" scoped>
.msg-box-template {
  width: 400px;
  font-size: 0;
  img {
    width: 100%;
  }
  .btn-close {
    position: absolute;
    top: 15px;
    right: 26px;
  }
  .main {
    padding: 32px;
    box-sizing: border-box;
    text-align: center;
    p {
      margin-top: 0;
      font-size: 24px;
      font-family: PingFangSC-Medium;
    }
    .content {
      position: relative;
      min-height: 100px;
      font-size: 20px;
      text-align: left;
      color: #999999;
      font-family: PingFangSC-Regular;
      .tip {
        position: absolute;
        bottom: 0;
        text-align: left;
        font-size: 13px;
        color: #999999;
        span {
          color: #ff3160;
        }
      }
      .tip-placeholder {
        height: 50px;
      }
    }
    .btn-group {
      display: flex;
      justify-content: space-between;
      ::v-deep .v-btn {
        width: 150px;
      }
    }
  }
}
</style>