<!--
 * @Author: wyq
 * @Date: 2021-08-03 16:09:10
 * @LastEditTime: 2021-12-15 16:35:59
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\dialogs\beginCatMsgBox.vue
-->
<template>
  <msg-box-template class="begin-error-test-dialog" :visible.sync="dialogVisible" title="是否开始测试"
    :imgUrl="require('@/assets/images/dialog/start.png')">
    <v-btn color="primary" depressed block x-large class="rounded-sm" @click="submit" :loading="submitLoadding">开始测试
    </v-btn>
    <v-btn v-if="showPreview" text block depressed x-large @click="goPreview">查看已完成试题</v-btn>
    <v-btn v-else text block depressed x-large @click="close">暂不测试</v-btn>
  </msg-box-template>
</template>
<script>
import msgBoxTemplate from './msgBoxTemplate'
import mixin from '@/utils/dialogMixin'
export default {
  components: {
    msgBoxTemplate,
  },
  mixins: [mixin],
  props: {
    showPreview: Boolean,
  },
  methods: {
    goPreview(){
      this.$emit('goPreview')
    }
  },
}
</script>
<style lang="scss" scoped>
.begin-error-test-dialog {
  p:not(:first-of-type) {
    display: inline-block;
    width: 50%;
  }
  .v-btn.primary{
    margin-top: 78px;
  }
  .v-btn--text{
    color:#999;
  }
}
</style>