<template>
	<div class="circular-progress">
		<div class="bg">
			<div class="bg_left" :style="leftStyle"></div>
			<div class="bg_right" :style="rightStyle"></div>
		</div>
		<div class="inner">
			<div>{{ efficiency == -1 ? value + '%' : efficiency }}</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		value: { //进度百分比 （0-100）
			type: Number,
			default: 0
		},
		efficiency: { //解题效率 （0.1~x）
			type: Number,
			default: -1
		},
	},
	data() {
		return {
			leftStyle: null,
			rightStyle: null
		};
	},
	watch: {
		value(val) {
			if (val) {
				this.getStyle()
			}
		}
	},
	mounted() {
		this.getStyle()
	},
	methods: {
		getStyle() {
			if (this.value == 100) {
				this.leftStyle = 'background:#0090FC';
				this.rightStyle = 'background:#0090FC';
			} else if (this.value == 0) {
				this.leftStyle = null;
				this.rightStyle = null;
			} else if (this.value > 50) {
				let n = (100 - this.value) * 2;
				let nTop = n - 1
				this.leftStyle = 'background-image: linear-gradient(#F8F8F8 0%, #F8F8F8 ' + nTop + '%,#0090FC ' + n + '%,#51AEF2 100%);'
				this.rightStyle = 'background: linear-gradient(#F8F8F8, #51AEF2);'
			} else if (this.value > 0) {
				let n = this.value * 2;
				let nTop = n + 1;
				this.leftStyle = null;
				this.rightStyle = 'background-image: linear-gradient(#F8F8F8 0%, #51AEF2 ' + n + '%,#F8F8F8 ' + nTop + '%,#F8F8F8 100%);';
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.circular-progress {
	display: inline-block;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	overflow: hidden;
}

.bg {
	width: 100%;
	height: 100%;
	margin: 0 auto;
	position: relative;

	>div {
		width: 50%;
		height: 100%;
		position: absolute;
		top: 0;
		overflow: hidden;
	}

	.bg_left {
		left: 0;
	}

	.bg_right {
		right: 0;
	}
}

.inner {
	display: flex;
	align-items: center;
	justify-content: center;
	width: calc(100% - 4px);
	height: calc(100% - 4px);
	margin: calc(-100% + 2px) auto 0;
	background: #fff;
	border-radius: 46px;
	position: relative;

	>div {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 90%;
		height: 90%;
		background: #fff;
		box-shadow: 0px 6px 18px 0px rgba(26, 67, 134, 0.12);
		border-radius: 50%;
		text-align: center;
		color: #454545;
		font-size: 12px;
		font-weight: bold;
		vertical-align: middle;
	}

}
</style>
