<!--
 * @Author: yy
 * @Date: 2021-10-02 22:37:50
 * @LastEditTime: 2021-10-27 16:18:39
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\loadMore\loadMore.vue
-->
<template>
  <div @scroll="onScroll" class="scrollView" :style="'height:'+defaultHeight" ref="articleListBox">
    <slot />
    <div v-if="noMoreTips" class="tips">没有更多了~</div>
    <img
      v-if="noData&&(path == 'trainingCenter' || path == 'adaptiveTesting')"
      class="trainingCenter-noDataImg"
      src="@/assets/images/table/trainingCenter-noData.png"
    />
    <div v-if="noData&&(path == 'trainingCenter')" class="trainingCenter-noDataTips">
      暂无可训练内容
      <br />请先进行自适应测试
    </div>
    <div v-if="noData&&(path == 'adaptiveTesting')" class="trainingCenter-noDataTips">暂无可训练内容</div>
    <img
      v-if="noData&&(path == 'wrongQuestion')"
      class="wrongQuestion-noDataImg"
      src="@/assets/images/table/wrongQuestion-noData.png"
    />
    <div v-if="noData&&(path == 'wrongQuestion')" class="wrongQuestion-noDataTips">暂无数据</div>
    <div v-if="noData&&(path == 'academicArchives')" class="academicArchives-noDataTips">暂无评估数据</div>
  </div>
</template>
<script>
export default {
  props: {
    defaultHeight: {
      type: String,
      default: 'calc(100vh - 100px)',
    },
    defaultPageNo: {
      type: [Number, String],
      default: 1,
    },
    defaultPageSize: {
      type: [Number, String],
      default: 10,
    },
    path: String,
  },
  data: () => ({
    list: [],
    conScrollHeight: '',
    conClientHeight: '',
    conScrollTop: '',
    pageNo: 1, //当前页
    pageSize: 10, //每页几条
    total: 0, //总条数
    noMoreTips: false,
    noData: false,
    type: -1,
  }),
  mounted() {
    if (this.type == 0) {
      setTimeout(() => {
        this.$refs.articleListBox.scrollTop = localStorage.getItem('scTop')
        this.type = -1
      }, 50)
    }
    this.$refs.articleListBox.addEventListener(
      'scroll',
      () => {
        localStorage.setItem('scTop', this.$refs.articleListBox.scrollTop)
      },
      false
    )
  },
  created() {
    if (this.pageSize != this.defaultPageSize) {
      this.pageSize = this.defaultPageSize
    }
    if (localStorage.getItem('status') == 'true') {
      //从需要缓存数据的下页返回的，数据不重新请求接口
      this.pageNo = Number(localStorage.getItem('pageNo'))
      this.type = 0
      this.total = localStorage.getItem('total')
      this.$emit('update:total', this.total)
      this.complete(JSON.parse(localStorage.getItem('list')))
    } else {
      if (this.pageNo != this.defaultPageNo) {
        this.pageNo = this.defaultPageNo
      }
      this.getList()
    }
  },
  methods: {
    getList() {
      setTimeout(() => {
        this.$emit('query', this.pageNo, this.pageSize)
      }, 20)
    },
    complete(arr, total) {
      if (total) {
        this.total = total
      }
      if (this.list.length) {
        this.list = this.list.concat(arr)
      } else {
        this.list = arr
      }
      if (!arr && !this.list) {
        this.noData = true
      } else if (arr.length < this.pageSize) {
        this.noMoreTips = true
      }
      this.$emit('update:list', this.list)
      //缓存数据  为了返回时不用读取数据
      localStorage.setItem('list', JSON.stringify(this.list))
      localStorage.setItem('pageNo', this.pageNo)
      localStorage.setItem('total', this.total)
    },
    //重新加载数据
    reReload() {
      this.list = []
      this.$emit('update:list', this.list)
      this.total = 0
      this.$emit('update:total', this.total)
      this.pageNo = 1
      this.noMoreTips = false
      this.noData = false
      this.$emit('query', this.pageNo, this.pageSize)
    },
    onScroll(e) {
      this.conScrollHeight = e.target.scrollHeight
      this.conClientHeight = e.target.clientHeight
      this.conScrollTop = e.target.scrollTop
      //内容滚动了的高度 +  区域内容的高度 >= 可以滚动区域的高度
      if (this.conScrollTop + this.conClientHeight >= this.conScrollHeight) {
        console.log(this.total, this.list.length)
        if (this.total && this.total > this.list.length) {
          this.pageNo++
          this.getList()
        } else if (!this.total && this.list.length % this.pageSize == 0) {
          this.pageNo++
          this.getList()
        } else {
          this.noMoreTips = true
        }
      }
    },
  },
}
</script> 
<style lang="scss" scoped>
.scrollView {
  width: 100%;
  overflow: auto;
  .tips {
    text-align: center;
    margin-bottom: 40px;
    clear: both;
    color: #999;
  }
  .noDataTips {
    color: #999;
    text-align: center;
    font-size: 24px;
  }

  //训练中心展示
  .trainingCenter-noDataImg {
    width: 240px;
    height: 200px;
    display: flex;
    align-items: center;
    margin: 20vh auto 20px;
  }
  .trainingCenter-noDataTips {
    color: #d9e3fc;
    text-align: center;
    font-size: 20px;
  }
  //错题中心展示
  .wrongQuestion-noDataImg {
    width: 240px;
    height: 200px;
    display: flex;
    align-items: center;
    margin: 20vh auto 20px;
  }
  .wrongQuestion-noDataTips {
    color: #999;
    text-align: center;
    font-size: 20px;
  }
  //学情档案展示
  .academicArchives-noDataTips {
    color: #999;
    text-align: center;
    margin-top: 20vh;
    font-size: 24px;
  }
}
</style> 

