<template>
  <el-container class="teacher-studio">
    <el-header>
      <public-header :leftArrow="false">
        <span>名师工作室</span>
        <div class="revisedBtn" @click="goStatistics">做题统计</div>
      </public-header>
    </el-header>
    <loadMore ref="more" @query="queryList" :list.sync="list" :defaultPageSize="1000" defaultHeight="calc(100vh - 77px)"
      path="adaptiveTesting">
      <el-main>
        <div class="card-container" v-for="(item, index) in list" :key="index" @click="toTrainingSet(item)">
          <div class="card-body">
            <div class="flex-box">
              <div class="content-box">
                <div class="card-title">{{ item.trainSetName }}</div>
                <!-- <div class="regular-text">Today，23 Mar.</div> -->
              </div>
              <div class="time-box">
                <div>平均每题用时</div>
                <div>{{ getAverageTime(item.costTime, item.questionNumFinished) }}</div>
              </div>
            </div>
            <div class="progress-text">
              <div>建构完成度</div>
              <div>{{ item.progress }}%</div>
            </div>
            <v-progress-linear :value="item.progress" background-color="#fff" background-opacity="0.2" color="#fff"
              rounded>
            </v-progress-linear>
          </div>
          <div class="card-footer">
            <div class="progress-warp">
              <circularProgress :value="item.correctRate"></circularProgress>
              <span>正确率</span>
              <i class="icon" :class="getIcon(item.correctRate, item.lastCorrectRate)"></i>
            </div>
            <div class="progress-warp">
              <circularProgress :value="item.efficiency * 100" :efficiency="item.efficiency">
              </circularProgress>
              <span>解题效率</span>
              <i class="icon" :class="getIcon(item.efficiency, item.lastEfficiency)"></i>
            </div>
          </div>
        </div>
      </el-main>
      <beginTeacherTestMsgBox :visible.sync="dialogVisible" :showPreview="currentTrain.finishedNum > 0"
        @submit="beginTest" @goPreview="goPreview">
      </beginTeacherTestMsgBox>
    </loadMore>
  </el-container>
</template>
<script>
import loadMore from '@/components/loadMore/loadMore'
import circularProgress from '../../components/teacherStudio/circularProgress'
import beginTeacherTestMsgBox from '../../components/dialogs/beginTeacherTestMsgBox'
export default {
  components: {
    loadMore,
    circularProgress,
    beginTeacherTestMsgBox
  },
  data() {
    return {
      list: [],
      dialogVisible: false,
      currentTrain: {}
    }
  },
  methods: {
    queryList() {
      this.$service.getFamousTeacherTrainList().then((res) => {
        // const topicList = this.$store.state.topicList
        // let result = res.list
        // for (let i of topicList) {
        //   result.push(res.list[i])
        // }
        // 节选
        res.list.map(r => {
          //正确率 
          let rate = r.correctNum / r.finishedNum * 100 || 0;
          r.correctRate = Number(rate.toString().match(/^\d+(?:\.\d{0,1})?/));
          //过滤的正确率
          let filterRate = r.questionNumCorrect / r.questionNumFinished * 100 || 0;
          let filterCorrectRate = Number(filterRate.toString().match(/^\d+(?:\.\d{0,1})?/));
          // 学习进度
          let progress = r.finishedNum / r.questionNumAll * 100 || 0;
          r.progress = Number(progress.toString().match(/^\d+(?:\.\d{0,2})?/));

          //历史正确率（未过滤的历史正确做题数/未过滤的历史全部做题数）
          let lastRate = r.lastCorrectNum / r.lastFinishedNum * 100 || 0;
          r.lastCorrectRate = Number(lastRate.toString().match(/^\d+(?:\.\d{0,2})?/));
          //过滤的历史正确率（过滤的历史正确做题数/过滤的历史全部做题数）
          let filterLastRate = r.lastQuestionNumCorrect / r.lastQuestionNumFinished * 100 || 0;
          let filterLastCorrectRate = Number(filterLastRate.toString().match(/^\d+(?:\.\d{0,2})?/));
          //解题效率 = 过滤的正确率x（avgCostTime/(costTime/questionNumFinished)）
          let efficiency = filterCorrectRate * (r.avgCostTime / (r.costTime / r.questionNumFinished)) / 100
          r.efficiency = Number(efficiency.toString().match(/^\d+(?:\.\d{0,1})?/));
          //历史解题效率
          let lastEfficiency = filterLastCorrectRate * (r.avgCostTime / (r.lastCostTime / r.lastQuestionNumFinished)) / 100
          r.lastEfficiency = Number(lastEfficiency.toString().match(/^\d+(?:\.\d{0,1})?/));
        })
        let arr = [res.list[1], res.list[0], res.list[2]]
        this.$refs.more.complete(arr)
      })
    },
    goDetail(id, name) {
      this.$router.push({
        name: 'AdaptiveTestingDetail',
        query: {
          id: id,
          name: name,
        },
      })
    },
    //每题平均用时
    getAverageTime(costTime, totalNum) {
      if (costTime == 0) {
        return '--'
      } else {
        let seconds = costTime / totalNum;
        let m = parseInt(seconds / 60);
        let s = parseInt(seconds - m * 60);
        if (m > 0) {
          let r = s / 60;
          return m + Number(r.toString().match(/^\d+(?:\.\d{0,2})?/)) + 'min'
        } else {
          return Number(seconds.toString().match(/^\d+(?:\.\d{0,2})?/)) + 's'
        }
      }
    },
    //上升下降图标
    getIcon(now, history) {
      if (now == history) {
        return 'icon-nomal'
      } else if (now > history) {
        console.log(now, history)
        return 'icon-up'
      } else {
        return 'icon-down'
      }
    },
    //跳转训练集
    toTrainingSet(e) {
      if (e.trainSetId == 3350 || e.trainSetId == 5852) {
        //所有题目都做完了，直接查看已完成试题
        if (e.finishedNum == e.questionNumAll) {
          this.$router.push({
            name: 'PreviewTesting',
            query: {
              type: 'teacherFinish',
              id: e.trainSetId
            }
          });
        } else {
          this.currentTrain = e;
          this.dialogVisible = true;
        }
      } else {
        this.$router.push({
          name: 'TrainingSet',
          query: {
            famousTeacherId: e.famousTeacherId,
            trainSetId: e.trainSetId
          }
        })
      }
    },
    // 开始测试
    beginTest() {
      console.log(this.currentTrain)
      if (this.currentTrain.questionNumFinished < this.currentTrain.questionNumAll) {
        this.$router.push({
          name: 'StartErrorTesting',
          query: {
            type: 'teacher',
            id: this.currentTrain.trainSetId,
          },
        })
      } else {
        this.goPreview();
      }

    },
    // 查看已完成试题
    goPreview() {
      this.$router.push({
        name: 'PreviewTesting',
        query: {
          type: 'teacherFinish',
          id: this.currentTrain.trainSetId,
        },
      })
    },
    goStatistics() {
      this.$router.push({
        name: 'TrainingStatistics'
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.teacher-studio {
  .revisedBtn {
    float: right;
    width: 120px;
    height: 40px;
    margin-top: 12px;
    background: #ffffff;
    box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    text-align: center;
    font-size: 18px;
    line-height: 40px;
    color: #666;
    font-family: PingFangSC-Regular, PingFang SC;
  }

  .el-main {
    margin-left: -32px;
  }

  .card-container {
    position: relative;
    width: calc(50% - 32px);
    margin-left: 32px;
    margin-bottom: 32px;
    display: inline-block;
    // height: 228px;
    border-radius: 16px;
    overflow: hidden;
    background-color: #fff;
    font-size: 14px;
  }

  .card-body {
    position: relative;
    padding: 24px 28px;
    background: #759BFF;
    color: #fff;

    &::before {
      position: absolute;
      right: -4px;
      top: -34px;
      width: 120px;
      height: 120px;
      background: #F2F6FC;
      opacity: 0.12;
      border-radius: 50%;
      content: "";
    }

    .flex-box {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .content-box {
      flex: 1;
      min-width: 0;

      .card-title {
        width: 100%;
        margin-bottom: 6px;
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-family: PingFangSC-Medium, PingFang SC;
      }
    }

    .time-box {
      position: relative;
      width: 120px;
      min-width: 120px;
      padding: 10px 14px;
      background: #B0C6FF;
      box-shadow: inset 2px 2px 2px 0px rgba(42, 42, 42, 0.5);
      border-radius: 16px;
      text-align: center;
    }

    .progress-text {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      margin-bottom: 2px;
      opacity: 0.8;
    }
  }

  .card-footer {
    display: flex;

    .progress-warp {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      padding: 12px 20px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;

      span {
        vertical-align: middle;
      }

      .icon {
        color: #759BFF;
        font-size: 30px;
        vertical-align: middle;
      }

      &:first-of-type::after {
        position: absolute;
        top: 12px;
        right: 0;
        width: 2px;
        height: 48px;
        background-color: #eee;
        content: "";
      }
    }
  }

  .v-card {
    padding: 24px 32px;

    .label {
      font-size: 20px;
    }

    .score {
      display: inline-block;
      width: 120px;

      p:first-of-type {
        font-size: 56px;
        color: #333;
        margin-bottom: 14px;
        margin-top: 24px;
        text-align: center;
        line-height: 68px;
        font-family: Akrobat-Black;
      }

      p:nth-of-type(2) {
        font-size: 18px;
        color: #999;
        margin-bottom: 0;
        text-align: center;
        font-family: PingFangSC-Regular;
      }
    }

    .echart-container {
      float: right;
    }

    .noDataTips {
      float: right;
      color: #d1d1d1;
      font-size: 30px;
      margin: 40px 30px 0 0;
    }
  }
}
</style>